import { graphql } from "gatsby";
import {
  GatsbyImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";
import React from "react";
import Masonry from "react-masonry-css";
import ContactSection from "../components/contact/ContactSection/ContactSection";
import HeroSection from "../components/HeroSection/HeroSection";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { cleanImgBase, Images } from "../utils/findImage";

interface CommercialPageProps {
  location: Location;
  data: {
    bgImage: {
      gatsbyImageData: IGatsbyImageData;
    };
    projectImages: Images;
  };
}

const CommercialPage: React.FC<CommercialPageProps> = ({ location, data }) => {
  return (
    <Layout>
      <SEO
        title="Commercial Painting Charlotte, NC | Over 15 Years of Experience"
        description="We provide the highest quality painting services for commercial spaces in the Charlotte area."
        slug={location.pathname}
      />
      <HeroSection
        bgImage={data.bgImage.gatsbyImageData}
        heading="Commercial painting"
      >
        Our experienced team can paint any surface inside or outside of your
        business
      </HeroSection>

      <section>
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-16 lg:items-center">
            <div className="w-full">
              <div className="relative">
                <StaticImage
                  src="../images/projects/commercial-painting-1.jpg"
                  alt="Commercial painting"
                  objectFit="cover"
                  width={600}
                  height={600}
                  className="z-20"
                />
                <div className="absolute top-8 -left-8 max-h-full max-w-full w-[584px] h-[584px] z-0 overflow-hidden">
                  <div className="bg-[#f8f8fa] w-full h-full" />
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-black mb-6">
                Providing the highest quality painting for commercial spaces
              </h2>
              <p className="mb-4">
                We work side by side with industry experts, including
                developers, business owners, and architectural designers, to
                create innovative and beautiful spaces with top-of-the-line
                finishes.
              </p>
              <p>
                We offer a wide variety of options in the commercial space,
                including but not limited to industrial coatings, high gloss
                lacquers, and automotive finishes.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-0">
        <div>
          <Masonry
            breakpointCols={{ default: 4, 640: 1, 1024: 2 }}
            className="col-span-full flex flex-wrap"
            columnClassName="px-0.5 bg-clip-padding"
          >
            {data.projectImages.nodes.map((node, index) => (
              <div key={`projectImage-${index}`} className="mb-1">
                <GatsbyImage
                  image={node.childImageSharp.gatsbyImageData}
                  alt={cleanImgBase(node.base)}
                />
              </div>
            ))}
          </Masonry>
        </div>
      </section>

      <ContactSection>
        Contact us for a free estimate and let us make your business stand out.
      </ContactSection>
    </Layout>
  );
};

export default CommercialPage;

export const pageQuery = graphql`
  query {
    bgImage: imageSharp(original: { src: { regex: "/exterior-painting-1/" } }) {
      id
      gatsbyImageData(layout: FULL_WIDTH)
    }
    projectImages: allFile(
      filter: {
        base: {
          in: [
            "commercial-painting-1.jpg"
            "commercial-painting-2.jpg"
            "commercial-painting-3.jpg"
            "commercial-painting-4.jpg"
          ]
        }
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90, aspectRatio: 0.75)
          original {
            src
          }
        }
        base
      }
    }
  }
`;
